<template>
  <Auth :clear="true">
    <section class="card-auth-admin mt-card">
      <div class="logo-sesi">
        <ApplicationLogo />
      </div>

      <b-alert
          v-if="alert.show"
          show
          fade
          variant="danger"
      >
        <div class="custom-alert">
              <span>
                <BIconExclamationCircle />
                {{ alert.message }}
              </span>
        </div>
      </b-alert>

      <div class="auth-card-title mt-3">
        <h2>
          Seja bem-vind@!
        </h2>
      </div>

      <div class="auth-card-text mb-2">
        <p class="text-center">
          Para fazer login na plataforma
          </br>
          Cliente Sesi+Saúde utilize sua conta Google
          </br>
          vinculada com alguma instituição da FIESC.
        </p>
      </div>

      <button :disabled="disabledButton" @click="login" type="button" class="btn button-form btn-block mb-2">
        <BIconGoogle class="mr-1" />
        {{ msgButton }}
      </button>

      <b-link :to="{name:'auth-login'}">
        <BIconChevronLeft/>
        <span class="ml-1">Volte ao Login Principal</span>
      </b-link>
    </section>
  </Auth>
</template>

<script>
import Auth from "@/views/pages/authentication/BaseAuth/Auth";
import {
  BCardText,
  BCardTitle,
  BIconExclamationCircle,
  BIconGoogle,
  BIconChevronLeft,
  BLink,
  BAlert,
} from 'bootstrap-vue'
import ApplicationLogo from "@/views/components/custom/logo-sesi/ApplicationLogo";
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { validation } from '@core/mixins/validation/validation'
import { messages } from "@core/mixins/validation/messages";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Auth,
    BCardText,
    BCardTitle,
    BLink,
    BAlert,
    BIconExclamationCircle,
    BIconGoogle,
    BIconChevronLeft,
    ApplicationLogo,
  },
  mixins: [togglePasswordVisibility, validation, messages],
  data() {
    return {
      auth: {
        googleAuthToken: '',
      },
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      alert: {
        show: false,
        message: '',
        variant: ''
      },

      loadingShow: true,

      disabledButton: false,
      msgButton: 'Continuar com o Google',

      formErrors: {},
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  methods: {
    async login() {
      await this.googleAuthVerify();

      if(this.auth.googleAuthToken) {
        this.buttonLoading(true);

        const auth = {
          userData: this.auth,
          admin: true
        }

        await this.$store.dispatch('sessions/login', auth)
            .then((response) => {
              const status = response.request.status;
              const token = response.data.auth.access_token;
              const userData = response.data;

              if(status === 200 && token) {
                this.abilitySessionAndRedirect(userData);
              }
            })
            .catch(error => {
              this.errorHandling(error.response);
            })

        this.buttonLoading(false);
      }
    },

    async googleAuthVerify() {
      await this.$gAuth.signIn()
          .then((response) => {
            this.auth.googleAuthToken = response.getAuthResponse().access_token;
          })
          .catch(() => {
            this.showAlert(
                this.impossibleMsg,
                'danger'
            );
          })
    },

    abilitySessionAndRedirect(userData) {
      if(! userData.ability || userData.ability.length === 0){
        this.$store.dispatch('sessions/logout').then();
        this.$router.replace({ name: 'misc-not-enabled' });

        return false;
      }

      if(userData.status) {
        userData.status = 'active';
      }

      this.$ability.update(userData.ability);

      this.$router.replace({ name: 'bem-vindo' })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Bem-vindo(a) ${userData.fullName}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: '',
              },
            })
          })
    },

    errorHandling(response) {
      const status = response.status;
      const message = response.data.error || this.incorrectAuthMsg;

      if(status === 401) {
        this.showAlert(
            message,
            'danger'
        );
      } else {
        this.showAlert(
            this.impossibleMsg,
            'danger'
        );
      }
    },

    showAlert(message, variant) {
      this.alert.show = true;
      this.alert.message = message;
      this.alert.variant = variant;
    },

    buttonLoading(disabled) {
      if(disabled) {
        this.disabledButton = true;
        this.msgButton = 'Processando...';
      } else {
        this.disabledButton = false;
        this.msgButton = 'Entrar';
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
